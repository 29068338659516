import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./ProductPage.css";
import Footer from "../../components/Footer";

interface Product {
  title: string;
}

const Lacteos: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);

  // Fetch the JSON data
  useEffect(() => {
    fetch("/data/lacteos.json") // Adjust the path as necessary
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  return (
    <div>
      <Header title="Lácteos" backgroundImage="../lacteosp.jpg" />
      <ul className="product-list">
        {products.map((product, index) => (
          <li key={index} className="product-item">
            {product.title}
          </li>
        ))}
      </ul>
      <Footer />
    </div>
  );
};

export default Lacteos;
