import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ChevronDown } from "lucide-react";
import CategoryCard from "../components/CategoryCard";

import "./HeroSection.css"; // Import the CSS for Hero Section
import "./AboutUs.css"; // Import the CSS for About Us Section
import "./AccordionStyle.css"; // Import the CSS for Accordion
import "./SponsorBanner.css"; // Import the CSS for Sponsor Banner
import "./ProductSection.css"; // Import the CSS for Product Section
import "./Catalog.css"; // Import the CSS for Catalog Section
import Footer from "../components/Footer";

gsap.registerPlugin(ScrollTrigger);

const sponsorLogos = [
  "logos/reyma.png",
  "logos/lalablanco.png",
  "logos/bachoco.png",
  "logos/lyncott.png",
  "logos/lacosteña.png",
  "logos/soles.png",
  "logos/jc.png",
  "logos/donsaul.png",
  "logos/bonaprime.png",
];

const HeroSection: React.FC = () => {
  const heroRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    gsap.fromTo(
      heroRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: heroRef.current,
          start: "top 80%",
        },
      }
    );
  }, []);

  return (
    <div className="hero-section" ref={heroRef}>
      <div className="background-container">
        <div className="gradient-overlay"></div>
        <div className="background-image"></div>
      </div>
      <div className="text-section">
        <h1 className="hero-title">Proveedores de productos comestibles</h1>
        <p className="hero-subtitle">
          Somos líderes en la distribución de alimentos en todo el noroeste de
          México.
        </p>
        <button className="cta-button" onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>Contáctanos</button>
        <SponsorBanner logos={sponsorLogos} />
      </div>
      <div className="image-container">
        <img
          src="/veggiebag.png"
          alt="Floating Image"
          className="floating-image"
        />
      </div>
    </div>
  );
};


const AboutUsSection: React.FC = () => {
  const cardsContainerRef = useRef<HTMLDivElement | null>(null);
  const textCardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: cardsContainerRef.current, // Trigger when this section enters the viewport
        start: "top 80%", // When 80% of the section is visible
      },
    });

    // Card animation (fade in and move up)
    tl.fromTo(
      cardsContainerRef.current,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
      }
    );

    // Text inside the card animation
    tl.fromTo(
      textCardRef.current,
      {
        opacity: 0,
        y: 20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: "power3.out",
      },
      "-=0.5"
    );
  }, []);

  return (
    <div className="about-us-section">
      <div className="cards-container" ref={cardsContainerRef}>
        {/* Right Card - Text */}
        <div className="about-us-text" ref={textCardRef}>
          <h2 className="about-us-title">Nosotros</h2>
          <p className="about-us-description">
            <span className="viandas-about-title">Viandas Proveedores</span> es
            una empresa especializada en la distribución de productos
            comestibles en Sonora. Contamos con una amplia red de distribución y
            un catálogo con más de 200 variedades de productos, priorizando su
            cuidado integral para asegurar su alta calidad.
          </p>
          <p className="about-us-link">
            <a href="/about">
              Ver más<span className="arrow-right"> →</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  content,
  icon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const arrowRef = useRef<HTMLDivElement | null>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      gsap.fromTo(
        contentRef.current,
        { height: 0, opacity: 0 },
        { height: "auto", opacity: 1, duration: 0.5, ease: "power3.out" }
      );
    } else if (contentRef.current) {
      gsap.to(contentRef.current, {
        height: 0,
        opacity: 0,
        duration: 0.5,
        ease: "power3.inOut",
      });
    }

    if (arrowRef.current) {
      gsap.to(arrowRef.current, {
        rotate: isOpen ? 180 : 0,
        duration: 0.3,
        ease: "power2.out",
      });
    }
  }, [isOpen]);

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={toggleAccordion}>
        <div className="accordion-icon">{icon}</div>
        <div className="accordion-title">{title}</div>
        <div className="accordion-arrow" ref={arrowRef}>
          <ChevronDown />
        </div>
      </div>
      <div
        className="accordion-content"
        ref={contentRef}
        style={{ height: 0, overflow: "hidden" }}
      >
        {content}
      </div>
      <hr />
    </div>
  );
};

interface AccordionItemProps {
  title: string;
  content: string;
  icon: React.ReactNode;
}

const Accordion: React.FC = () => {
  const accordionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    gsap.fromTo(
      accordionRef.current,
      { opacity: 0, x: -50 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: accordionRef.current,
          start: "top 80%",
        },
      }
    );
  }, []);

  return (
    <div className="accordion" ref={accordionRef}>
      <AccordionItem
        title="Soluciones de distribución adaptadas según cada cliente"
        content="Solventamos las necesidades alimenticias específicas de cada negocio, desde comedores industriales, hospitales y restaurantes, hasta particulares, a través de una atención personalizada."
        icon={<img src="/garantia.png" alt="Garantía" />}
      />
      <AccordionItem
        title="Precios competitivos"
        content="Ofrecemos calidad y ahorro con precios competitivos en cada producto, respaldados por una red de proveedores estratégicos en todo el estado de Sonora."
        icon={<img src="/comp.png" alt="Compromiso" />}
      />
      <AccordionItem
        title="Flexibilidad para conseguir productos"
        content="Proveemos más allá de lo establecido en catálogo, cumpliendo con solicitudes adicionales o demandas especiales de cada cliente."
        icon={<img src="/punt.png" alt="Puntualidad" />}
      />
      <AccordionItem
        title="Responsabilidad y seguridad"
        content="Cumplimos con las regulaciones establecidas por COFEPRIS, garantizando que cada producto sea seguro y apto para el consumo."
        icon={<img src="/punt.png" alt="Puntualidad" />}
      />
      {/* Other Accordion Items */}
    </div>
  );
};

interface SponsorBannerProps {
  logos: string[]; // Array of image URLs for the sponsor logos
}

const SponsorBanner: React.FC<SponsorBannerProps> = ({ logos }) => {
  return (
    <div className="sponsor-banner">
      <div className="sponsor-container">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo}
            alt={`Sponsor ${index + 1}`}
            className="sponsor-logo"
          />
        ))}
      </div>
    </div>
  );
};

const ProductSection: React.FC = () => {
  return (
    <div className="product-section">
      {/* Background image with overlay */}
      <div className="product-section-background">
        <div className="overlay"></div>

        {/* Text and button */}
        <div className="product-section-content">
          <h1 className="product-section-title">
            ¿No encontraste el producto que buscabas?
          </h1>
          
        <button className="product-section-button" onClick={() => window.location.href = '/contact'}>Contáctanos</button>
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const accordionSectionRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);

      if (imageRef.current && accordionSectionRef.current) {
        if (mobile) {
          imageRef.current.style.height = "auto";
          imageRef.current.style.aspectRatio = "16 / 9"; // Adjust this ratio as needed
        } else {
          imageRef.current.style.height = `${accordionSectionRef.current.offsetHeight}px`;
          imageRef.current.style.aspectRatio = "auto";
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call to set height

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {/* ... (HeroSection and AboutUsSection remain the same) */}
      <HeroSection />
      <AboutUsSection />
      {/* New Propuestas Section */}
      <div className="propuestas-section">
        <div className={`container ${isMobile ? "mobile" : ""}`}>
          <div className="accordion-column" ref={accordionSectionRef}>
            <h1 className="propuestas-title">
              Comprometidos con la calidad, servicio y puntualidad en cada
              entrega
            </h1>
            <div className="accordion-section">
              <Accordion />
            </div>
          </div>

          <div className="image-card" ref={imageRef}>
            <img src="/corn.jpg" alt="Person Holding Corn" />
          </div>
        </div>
      </div>

      {/* Catalog Section */}
      <div className="category-container-home">
        <h1 className="catalog-title">Nuestro Catálogo</h1>
        <div className="category-cards-row">
          <CategoryCard
            title="Frutas y Verduras"
            // imageSrc="/frutas.png"
            bgColor="#fb921c"
            link="/categorias/frutas"
          />
          <CategoryCard
            title="Carnes"
            // imageSrc="/carnes.png"
            bgColor="#da4b43"
            link="/categorias/carnes"
          />
          <CategoryCard
            title="Lácteos"
            // imageSrc="/lacteos.png"
            bgColor="#c12b2c"
            link="/categorias/lacteos"
          />
          <CategoryCard
            title="Abarrotes"
            // imageSrc="/abarrotes.png"
            bgColor="#dc5b33"
            link="/categorias/abarrotes"
          />
        </div>
      </div>
      {/* Product Section */}
      <ProductSection />
      {/* Contact Us Section */}
      <Footer />
    </div>
  );
};

export default Home;
