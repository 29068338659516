import React from 'react';
import './Header.css';

interface HeaderProps {
    title: string;
    backgroundImage: string; // Add a new prop for the background image
}

const Header: React.FC<HeaderProps> = ({ title, backgroundImage }) => {
    const headerStyle = {
        backgroundImage: `url(${backgroundImage})`, // Use the prop for the background image
    };

    return (
        <div className="about-header" style={headerStyle}>
            <div className="header-content">
                <h1 className="nosotros-main-title">{title}</h1>
            </div>
        </div>
    );
};

export default Header;
