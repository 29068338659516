import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CategoryCard.css';

interface CategoryCardProps {
  title: string;
  // imageSrc: string;
  bgColor?: string;
  link: string; // Add a link prop for navigation
}

const CategoryCard: React.FC<CategoryCardProps> = ({ title, bgColor = '#f7f7f7', link }) => {
  const [hover, setHover] = useState(false);

  return (
    <Link 
      to={link} // Use the link prop to define the navigation target
      className={`category-card-container ${hover ? 'hovered' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ 
        '--bg-color': bgColor,
        '--hover-bg-color': adjustColor(bgColor, 10)
      } as React.CSSProperties}
    >
 
      <div className="textarea">
        <h2 className="title">{title}</h2>
        <p className="ver-mas">Ver Más</p>
      </div>
    </Link>
  );
};

// Helper function to darken the background color for hover effect
function adjustColor(color: string, amount: number): string {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export default CategoryCard;
