import React from "react";
import "./Nosotros.css";
import "./Valores.css";
import CarouselGallery from "../components/CarouselGallery";
import Footer from "../components/Footer";
import Header from "../components/Header";

const images = [
  // 'image1.jpg',
  // 'image2.jpg',

  "/nosotros/gallery2.jpeg",
  "/nosotros/gallery3.jpeg",
  "/nosotros/gallery4.jpeg",
  "/nosotros/gallery5.jpeg",
  "/nosotros/gallery1.jpeg",
  "/nosotros/1.jpeg",
  "/nosotros/2.jpeg",
  "/nosotros/3.jpeg",
 

  // Add more image URLs here
];

interface ValuesSectionProps {
  description: string;
  values: string[];
}

const ValuesSection: React.FC<ValuesSectionProps> = ({
  description,
  values,
}) => {
  return (
    <div className="values-section">
      {/* Main Description */}
      <div className="description">
        <p>{description}</p>
      </div>

      {/* Values */}
      <div className="values-grid">
        {values.map((value, index) => (
          <div className="value-item" key={index}>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

interface AboutCardProps {
  imageSrc: string;
  title: string;
  description: string;
  backgroundColor: string;
  titleColor?: string;
  descriptionColor?: string;
  reverse?: boolean;
}

const DesktopAboutCard: React.FC<AboutCardProps> = ({
  imageSrc,
  title,
  description,
  backgroundColor,
  titleColor,
  descriptionColor,
  reverse,
}) => {
  return (
    <div className={`desktop-about-card ${reverse ? "reverse" : ""}`}>
      <div className="image-wrapper">
        <img src={imageSrc} alt={title} className="card-image" />
      </div>
      <div className="card-text" style={{ backgroundColor }}>
        <h2 className="card-title" style={{ color: titleColor }}>
          {title}
        </h2>
        <p className="card-description" style={{ color: descriptionColor }}>
          {description}
        </p>
      </div>
    </div>
  );
};

const MobileAboutCard: React.FC<AboutCardProps> = ({
  imageSrc,
  title,
  description,
  backgroundColor,
  titleColor,
  descriptionColor,
}) => {
  return (
    <div className="mobile-about-card">
      <div className="image-wrapper">
        <img src={imageSrc} alt={title} className="card-image" />
      </div>
      <div className="card-text" style={{ backgroundColor }}>
        <h2 className="card-title" style={{ color: titleColor }}>
          {title}
        </h2>
        <p className="card-description" style={{ color: descriptionColor }}>
          {description}
        </p>
      </div>
    </div>
  );
};

const About: React.FC = () => {
  const cards = [
    {
      imageSrc: "/nosotros/1.jpeg",
      title: "Nosotros",
      description:
        "Viandas Proveedores es una empresa especializada en la distribución de productos comestibles en Sonora. Contamos con una amplia red de distribución y un catálogo con más de 200 variedades de productos, priorizando su cuidado integral para asegurar su alta calidad.",
      backgroundColor: "#dee7e7",
      titleColor: "#000000",
      descriptionColor: "#333333",
    },
    {
      imageSrc: "/nosotros/2.jpeg",
      title: "Misión",
      description:
        "Nos dedicamos a la distribución mayorista de productos alimenticios para atender las necesidades de comedores industriales, hospitales, restaurantes y empresas. Asegurando la entrega de productos frescos con la máxima calidad, puntualidad y excelencia en el trato en cada uno de nuestros servicios.",
      backgroundColor: "#e4d5cf",
      titleColor: "#000000",
      descriptionColor: "#000000",
      reverse: true,
    },
    {
      imageSrc: "/nosotros/3.jpeg",
      title: "Visión",
      description:
        "Ser líderes en distribución y comercialización de alimentos a nivel nacional, asegurando su calidad a través de la innovación continua, procesos automatizados, prácticas sostenibles y el buen servicio. Buscando ser una empresa referente y reconocida como socio estratégico preferido en la industria alimentaria.",
      backgroundColor: "#fdf8da",
      titleColor: "#000000",
      descriptionColor: "#000000",
    },
  ];

  return (
    <>
      <Header title='Nosotros' backgroundImage='./nosotros.jpg' />
  
      <div className="about-page-container">
        <div className="about-cards-container">
          {cards.map((card, index) => (
            <React.Fragment key={index}>
              <DesktopAboutCard {...card} />
              <MobileAboutCard {...card} />
            </React.Fragment>
          ))}
        </div>
      </div>
  
      {/* ValuesSection moved outside the about-page-container but remains in the correct order */}
      <ValuesSection
        description="En Viandas proveedores, nos guiamos por valores que definen nuestro compromiso para construir relaciones sólidas con nuestros clientes y la comunidad, fomentando el bienestar social y contribuyendo positivamente al entorno en el que operamos."
        values={[
          "Responsabilidad Social",
          "Transparencia",
          "Sostenibilidad",
          "Honestidad",
          "Calidad",
          "Integridad",
        ]}
      />
  
      <div className="carousel-page-container">
        <CarouselGallery images={images} />
      </div>
  
      <Footer />
    </>
  );
  
};

export default About;
