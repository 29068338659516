import React from 'react';
import emailjs from 'emailjs-com'; // Add EmailJS
import { Phone, Mail } from 'lucide-react';
import './Footer.css';

const Footer: React.FC = () => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent page reload

    // Send form data via EmailJS
    emailjs
      .sendForm(
        'viandascontact', // Replace with your EmailJS Service ID
        'template_upubr4u', // Replace with your EmailJS Template ID
        e.currentTarget,
        'WdzOEEdO4pIQUrDs4' // Replace with your EmailJS Public Key
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('¡Mensaje enviado con éxito!');
        },
        (error) => {
          console.error(error.text);
          alert('Hubo un error al enviar tu mensaje. Intenta nuevamente.');
        }
      );

    e.currentTarget.reset(); // Clear the form after submission
  };

  return (
    <div className="contact-section">
      <div className="contact-container">
        {/* Contact Information */}
        <div className="contact-info">
          <h2>CONTÁCTANOS</h2>
          <p className="footer-contact-text">
            Estamos a su disposición para atender sus requerimientos específicos de manera eficiente.
          </p>
          <div className="contact-icons">
            <Phone className="contact-icon" style={{ color: 'white' }} />
            <Mail className="contact-icon" style={{ color: 'white' }} />
          </div>
          {/* Updated Form with onSubmit */}
          <form className="contact-form" onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Nombre" required />
            <input type="email" name="email" placeholder="E-mail" required />
            <input type="tel" name="phone" placeholder="Teléfono" required />
            <textarea name="message" placeholder="Mensaje" required></textarea>
            <button type="submit" className="submit-btn">
              Enviar <span className="arrow-up">↗</span>
            </button>
          </form>
        </div>

        {/* Contact Address */}
        <div className="contact-address">
          <h2>DIRECCIÓN</h2>
          <p className="footer-address-text">
            Carretera a Bahía Kino KM14 S/N, detrás de hipódromo. La manga C.P. 83220. Hermosillo, Sonora.
          </p>
          <a href="mailto:viandasproveedores@outlook.com">viandasproveedores@outlook.com</a>
          <a href="tel:+526623997624">+52 (662) 399 76 24</a>
        </div>
      </div>

      {/* Footer */}
      <div className="footer">
        <p className="footer-credits">VIANDAS PROVEEDORES 2024</p>
        <p className="footer-author">BENJAMIN PARTIDA</p>
      </div>
    </div>
  );
};

export default Footer;
