import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { FaBars, FaTimes, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Menu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuItemsRef = useRef<HTMLUListElement | null>(null);
  const topBarRef = useRef<HTMLDivElement | null>(null);
  const tl = useRef<GSAPTimeline | null>(null);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    // Set initial state
    gsap.set(menuRef.current, {
      opacity: 0,
      y: "-100%", // Start from above the viewport
    });

    gsap.set(Array.from(menuItemsRef.current?.children || []), {
      y: 50,
      opacity: 0,
    });

    // Initialize GSAP timeline for animations
    tl.current = gsap.timeline({ paused: true });

    // Menu background animation (drop down and fade in)
    tl.current.to(menuRef.current, {
      duration: 0.5,
      opacity: 1,
      y: "0%",
      ease: "power1.inOut",
      pointerEvents: "auto",
    });

    // Menu items animation (fade in and slide up) - starts after background
    tl.current.fromTo(
      Array.from(menuItemsRef.current?.children || []),
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.1,
        ease: "power3.out",
      },
      ">"
    );

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    // Check if at the top
    setIsAtTop(currentScrollTop === 0);

    if (currentScrollTop > lastScrollTop.current) {
      // Scrolling down
      setIsVisible(false);
    } else {
      // Scrolling up
      setIsVisible(true);
    }

    lastScrollTop.current = currentScrollTop <= 0 ? 0 : currentScrollTop;
  };

  // Toggle the menu open/close
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      tl.current?.reverse();
    } else {
      // Reset and play timeline
      tl.current?.progress(0).play();
    }
  };

  return (
    <>
      {/* Top bar with hamburger, logo, and social icons */}
      <div
        ref={topBarRef}
        className={`top-bar ${isVisible ? "visible" : "hidden"} ${
          isAtTop ? "transparent" : ""
        }`}
      >
        <div className="hamburger" onClick={toggleMenu}>
          {isOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>
        <div className="logo">
          <a href="/">
            <img className="logo" src="/viandas.png" alt="Logo" />
          </a>
        </div>
        <div className="social-icons">
          <a
            href="https://wa.me/526623997624"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>
          <a
            href="https://www.instagram.com/viandasproveedores"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
      </div>

      {/* Full-screen menu overlay */}
      <div
        ref={menuRef}
        className={`menu ${isOpen ? "open" : ""}`}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        <ul ref={menuItemsRef} className="menu-items">
          <li>
            <a href="/" onClick={toggleMenu}>
              Inicio
            </a>
          </li>
          <li>
            <a href="/about" onClick={toggleMenu}>
              Nosotros
            </a>
          </li>
          <li>
            <a href="/catalogo" onClick={toggleMenu}>
              Catálogo
            </a>
          </li>
          <li>
            <a href="/contact" onClick={toggleMenu}>
              Contacto
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Menu;
