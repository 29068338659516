import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Contact: React.FC = () => {
  return (
    <div>
      <Header title='Contacto' backgroundImage='./contact.jpg'/>
      <Footer />
    </div>
  );
};

export default Contact;
