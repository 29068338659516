import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Home from './pages/Home';
import About from './pages/About';
import Catalog from './pages/Catalog';
import Contact from './pages/Contact';
import FrutasPage from './pages/categorias/Frutas';
import Abarrotes from './pages/categorias/Abarrotes';
import Carnes from './pages/categorias/Carnes';
import Lacteos from './pages/categorias/Lacteos';
import ScrollToTop from './components/ScrollToTop';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Menu />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/catalogo" element={<Catalog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/categorias/frutas" element={<FrutasPage/>} />
            <Route path="/categorias/abarrotes" element={<Abarrotes/>} />
            <Route path="/categorias/carnes" element={<Carnes/>} />
            <Route path="/categorias/lacteos" element={<Lacteos/>} />


          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
