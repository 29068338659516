import CategoryCard from "../components/CategoryCard";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Services: React.FC = () => {
  return (
    <>
      <Header title="Nuestro Catálogo" backgroundImage="./produce.jpg" />
      <div className="services-container">
        <p className="page-subtitle">
          En nuestro catálogo se presentan selectivamente algunos de los
          productos que conforman nuestra oferta. Queremos destacar que contamos
          con una amplia accesibilidad para adquirir cualquier producto que esté
          solicitando, incluso aquellos que no estén incluidos en esta selección
          específica.
        </p>
        <div className="category-cards-row">
          <CategoryCard
            title="Frutas y Verduras"
            // imageSrc="/frutas.png"
            bgColor="#fb921c"
            link="/categorias/frutas"
          />
          <CategoryCard
            title="Carnes"
            // imageSrc="/carnes.png"
            bgColor="#da4b43"
            link="/categorias/carnes"
          />
          <CategoryCard
            title="Lácteos"
            // imageSrc="/lacteos.png"
            bgColor="#c12b2c"
            link="/categorias/lacteos"
          />
          <CategoryCard
            title="Abarrotes"
            // imageSrc="/abarrotes.png"
            bgColor="#dc5b33"
            link="/categorias/abarrotes"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
